import { Component, HostBinding, Input } from '@angular/core';
import { PageLoadingImageComponent } from '../page-loading-image/page-loading-image.component';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { LoadTimerService } from '../../../../service/load-timer.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    PageLoadingImageComponent,
  ],
  selector: 'page-image',
  templateUrl: './page-image.component.html',
  styleUrl: './page-image.component.less'
})
export class PageImageComponent {

  isLoading$!: Observable<boolean>;

  @Input() imageSrc!: string;
  @Input() width: string = '100%';
  @Input() height: string = '100%';
  @Input() alt!: string;
  @Input() cursor!: boolean;

  // 圖片是否使用Loading
  @Input() loadingSwitch: boolean = true;

  @HostBinding('class') get hostClasses(): string {

    // 如果使用Loading 那就在元件上加上hasLoading的className
    return `page-image ${this.loadingSwitch ? 'hasLoading' : 'finishLoading'}`;
  }

  @HostBinding('style.cursor') get hostCursor(): string {
    return this.cursor ? 'pointer' : '';
  }

  @HostBinding('style') get hostStyles(): string {
    return `width:${this.width}; height:${this.height};`;
  }
  constructor(
    private loadTimerService: LoadTimerService,
  ) { }

  ngOnInit() {
    this.isLoading$ = this.loadTimerService.getMainLoadStatus();

  }
}
