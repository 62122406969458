import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
// import { DeviceDetectorService } from 'ngx-device-detector';
import { CustomDeviceDetectService } from '../../../service/custom-device-detect.service';

@Component({
  selector: 'main-container',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './main-container.component.html',
  styleUrl: './main-container.component.less'
})
export class MainContainerComponent {

  // deviceInfo: string = this.deviceService.getDeviceInfo().deviceType;
  deviceInfo: string = 'Unknown';

  @Input() overflow!: boolean;
  @Input() useTopFixedElem: boolean = false;



  @HostBinding('class') get hostClasses(): string {
    return `main-container `;
  }

  constructor(
    // private deviceService: DeviceDetectorService,
    private customDeviceService: CustomDeviceDetectService,
  ) { }

  ngOnInit(): void {
    // 同步設備類型的值
    this.customDeviceService.deviceInfo$.subscribe((type) => {
      this.deviceInfo = type;
    });
  }


}
