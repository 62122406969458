import { AppPathSetting } from "../../app-path";


export interface navMainList {
    order: number;
    name: string;
    displayStatus: boolean;
    checkRoute: boolean;
    routePath: string;
    checkURL: boolean;
    setURL: string;
    active: boolean,
    hasChild: boolean;
    childList: navChildList[];
}


export interface navChildList {
    order: number;
    name: string;
    routePath: string;
    fragment: string;
    displayStatus: boolean;
    checkURL: boolean;
    setURL: string;
    hasICON: string;
}


export const NavList: navMainList[] = [
    {
        order: AppPathSetting.about.order,
        name: AppPathSetting.about.name_CH,
        displayStatus: true,
        checkRoute: true,
        routePath: AppPathSetting.about.path,
        checkURL: false,
        setURL: '',
        active: false,
        hasChild: true,
        childList: [
            {
                order: AppPathSetting.about.children.intro.order,
                name: AppPathSetting.about.children.intro.name_CH,
                routePath: '',
                fragment: AppPathSetting.about.children.intro.fragment,
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.about.children.intro.icon,
            },
            {
                order: AppPathSetting.about.children.history.order,
                name: AppPathSetting.about.children.history.name_CH,
                routePath: '',
                fragment: AppPathSetting.about.children.history.fragment,
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.about.children.history.icon,
            },
            {
                order: AppPathSetting.about.children.team.order,
                name: AppPathSetting.about.children.team.name_CH,
                routePath: '',
                fragment: AppPathSetting.about.children.team.fragment,
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.about.children.team.icon,
            },
            {
                order: AppPathSetting.about.children.organization.order,
                name: AppPathSetting.about.children.organization.name_CH,
                routePath: '',
                fragment: AppPathSetting.about.children.organization.fragment,
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.about.children.organization.icon,
            },
            {
                order: AppPathSetting.about.children.affiliate.order,
                name: AppPathSetting.about.children.affiliate.name_CH,
                routePath: '',
                fragment: AppPathSetting.about.children.affiliate.fragment,
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.about.children.affiliate.icon,
            },

        ],
    },
    {
        order: AppPathSetting.showcase.order,
        name: AppPathSetting.showcase.name_CH,
        displayStatus: true,
        checkRoute: true,
        routePath: AppPathSetting.showcase.path,
        checkURL: false,
        setURL: '',
        active: false,
        hasChild: false,
        childList: [
            {
                order: AppPathSetting.showcase.children.classic.order,
                name: AppPathSetting.showcase.children.classic.name_CH,
                routePath: AppPathSetting.showcase.children.classic.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.showcase.children.classic.icon
            },
            {
                order: AppPathSetting.showcase.children.latest.order,
                name: AppPathSetting.showcase.children.latest.name_CH,
                routePath: AppPathSetting.showcase.children.latest.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.showcase.children.latest.icon,
            },
            {
                order: AppPathSetting.showcase.children.preparation.order,
                name: AppPathSetting.showcase.children.preparation.name_CH,
                routePath: AppPathSetting.showcase.children.preparation.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.showcase.children.preparation.icon
            },
        ],
    },
    {
        order: AppPathSetting.investor.order,
        name: AppPathSetting.investor.name_CH,
        displayStatus: true,
        checkRoute: true,
        routePath: AppPathSetting.investor.path,
        checkURL: false,
        setURL: '',
        active: false,
        hasChild: true,
        childList: [
            {
                order: AppPathSetting.investor.children.IR.order,
                name: AppPathSetting.investor.children.IR.name_CH,
                routePath: AppPathSetting.investor.children.IR.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.investor.children.IR.icon,
            },
            {
                order: AppPathSetting.investor.children.finance.order,
                name: AppPathSetting.investor.children.finance.name_CH,
                routePath: AppPathSetting.investor.children.finance.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.investor.children.finance.icon,
            },
            {
                order: AppPathSetting.investor.children.service.order,
                name: AppPathSetting.investor.children.service.name_CH,
                routePath: AppPathSetting.investor.children.service.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.investor.children.service.icon,
            },
            {
                order: AppPathSetting.investor.children.major.order,
                name: AppPathSetting.investor.children.major.name_CH,
                routePath: AppPathSetting.investor.children.major.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.investor.children.major.icon,
            },
            {
                order: AppPathSetting.investor.children.shareholders.order,
                name: AppPathSetting.investor.children.shareholders.name_CH,
                routePath: AppPathSetting.investor.children.shareholders.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.investor.children.shareholders.icon,
            },
        ],
    },
    {
        order: AppPathSetting.governance.order,
        name: AppPathSetting.governance.name_CH,
        displayStatus: true,
        checkRoute: true,
        routePath: AppPathSetting.governance.path,
        checkURL: false,
        setURL: '',
        active: false,
        hasChild: true,
        childList: [
            {
                order: AppPathSetting.governance.children.policies.order,
                name: AppPathSetting.governance.children.policies.name_CH,
                routePath: AppPathSetting.governance.children.policies.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.governance.children.policies.icon,
            },
            {
                order: AppPathSetting.governance.children.stakeholder.order,
                name: AppPathSetting.governance.children.stakeholder.name_CH,
                routePath: AppPathSetting.governance.children.stakeholder.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.governance.children.stakeholder.icon,
            },
            {
                order: AppPathSetting.governance.children.csr.order,
                name: AppPathSetting.governance.children.csr.name_CH,
                routePath: AppPathSetting.governance.children.csr.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.governance.children.csr.icon,
            },
        ],
    },
    //都更專區
    {
        order: AppPathSetting.urban.order,
        name: AppPathSetting.urban.name_CH,
        displayStatus: true,
        checkRoute: true,
        routePath: AppPathSetting.urban.path,
        checkURL: false,
        setURL: '',
        active: false,
        hasChild: false,
        childList: [],
    },
    //聯絡我們
    {
        order: AppPathSetting.contact.order,
        name: AppPathSetting.contact.name_CH,
        displayStatus: true,
        checkRoute: true,
        routePath: AppPathSetting.contact.path,
        checkURL: false,
        setURL: '',
        active: false,
        hasChild: true,
        childList: [
            {
                order: AppPathSetting.contact.children.info.order,
                name: AppPathSetting.contact.children.info.name_CH,
                routePath: AppPathSetting.contact.children.info.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.contact.children.info.icon,
            },
            {
                order: AppPathSetting.contact.children.service.order,
                name: AppPathSetting.contact.children.service.name_CH,
                routePath: AppPathSetting.contact.children.service.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.contact.children.service.icon,
            },
        ],
    },
    //會員中心
    {
        order: AppPathSetting.member.order,
        name: AppPathSetting.member.name_CH,
        displayStatus: true,
        checkRoute: true,
        routePath: AppPathSetting.member.path,
        checkURL: false,
        setURL: '',
        active: false,
        hasChild: true,
        childList: [

            {
                order: AppPathSetting.member.children.payment.order,
                name: AppPathSetting.member.children.payment.name_CH,
                routePath: AppPathSetting.member.children.payment.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.member.children.payment.icon,
            },
            {
                order: AppPathSetting.member.children.feedback.order,
                name: AppPathSetting.member.children.feedback.name_CH,
                routePath: AppPathSetting.member.children.feedback.path,
                fragment: '',
                displayStatus: true,
                checkURL: false,
                setURL: '',
                hasICON: AppPathSetting.member.children.feedback.icon,
            },
            // {
            //     order: AppPathSetting.member.children.constructionProgress.order,
            //     name: AppPathSetting.member.children.constructionProgress.name_CH,
            //     routePath: AppPathSetting.member.children.constructionProgress.path,
            //     fragment: '',
            //     displayStatus: true,
            //     checkURL: false,
            //     setURL: '',
            //     hasICON: AppPathSetting.member.children.constructionProgress.icon,
            // },
            // {
            //     order: AppPathSetting.member.children.support.order,
            //     name: AppPathSetting.member.children.support.name_CH,
            //     routePath: AppPathSetting.member.children.support.path,
            //     fragment: '',
            //     displayStatus: true,
            //     checkURL: false,
            //     setURL: '',
            //     hasICON: AppPathSetting.member.children.support.icon,
            // },
            // {
            //     order: AppPathSetting.member.children.download.order,
            //     name: AppPathSetting.member.children.download.name_CH,
            //     routePath: AppPathSetting.member.children.download.path,
            //     fragment: '',
            //     displayStatus: true,
            //     checkURL: false,
            //     setURL: '',
            //     hasICON: AppPathSetting.member.children.download.icon,
            // },

        ],
    },

    //登入畫面
    {
        order: AppPathSetting.login.order,
        name: AppPathSetting.login.name_CH,
        displayStatus: false,
        checkRoute: true,
        routePath: AppPathSetting.login.path,
        checkURL: false,
        setURL: '',
        active: false,
        hasChild: false,
        childList: [],
    },

]
