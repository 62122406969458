import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadTimerService {


  // 設置主要Loading畫面

  private mainLoadStatus$ = new BehaviorSubject<boolean>(true);

  public setMainLoadStatus(mainLoadStatus: boolean) {
    this.mainLoadStatus$.next(mainLoadStatus);
  }

  public getMainLoadStatus(): Observable<boolean> {
    return this.mainLoadStatus$.asObservable();
  }


  // 設置接續在Main Loading 畫面後的渲染時間

  private elementDisplayStatus$ = new BehaviorSubject<boolean>(false);


  public setElementDisplayStatus(elementDisplayStatus: boolean) {
    this.elementDisplayStatus$.next(elementDisplayStatus);
  }


  public getElementDisplayStatus(): Observable<boolean> {
    return this.elementDisplayStatus$.asObservable();
  }


  // 設置選單Item渲染時間

  private navItemDisplayStatus$ = new BehaviorSubject<boolean>(false);


  public setNavItemDisplayStatus(navItemDisplayStatus: boolean) {
    this.navItemDisplayStatus$.next(navItemDisplayStatus);
  }


  public getNavItemDisplayStatus(): Observable<boolean> {
    return this.navItemDisplayStatus$.asObservable();
  }

  constructor() { }


}
