import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageInfoService {


  //頁面型態
  private pageType$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public setPageType(pageType: string): void {
    this.pageType$.next(pageType);
  }

  public getPageType(): Observable<string> {
    return this.pageType$.asObservable();
  }

  //頁面順序
  private pageOrder$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public setPageOrder(pageOrder: number): void {
    this.pageOrder$.next(pageOrder);
  }

  public getPageOrder(): Observable<number> {
    return this.pageOrder$.asObservable();
  }


  //路徑
  private RtPath$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public setRtPath(RtPath: string): void {
    this.RtPath$.next(RtPath);
  }

  public getRtPath(): Observable<string> {
    return this.RtPath$.asObservable();
  }


  //中文標題
  private pageTitle_CH$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public setPageTitle_CH(pageTitle_CH: string): void {
    this.pageTitle_CH$.next(pageTitle_CH);
  }

  public getPageTitle_CH(): Observable<string> {
    return this.pageTitle_CH$.asObservable();
  }


  //英文標題
  private pageTitle_EN$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public setPageTitle_EN(pageTitle_EN: string): void {
    this.pageTitle_EN$.next(pageTitle_EN);
  }

  public getPageTitle_EN(): Observable<string> {
    return this.pageTitle_EN$.asObservable();
  }


  //頁面描述
  private pageDescription$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public setPageDescription(pageDescription: string): void {
    this.pageDescription$.next(pageDescription);
  }

  public getPageDescription(): Observable<string> {
    return this.pageDescription$.asObservable();
  }


  //頁面關鍵字
  private pageKeyWords$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public setPageKeyWords(pageKeyWords: string): void {
    this.pageKeyWords$.next(pageKeyWords);
  }

  public getPageKeyWords(): Observable<string> {
    return this.pageKeyWords$.asObservable();
  }


  //頁面主視覺
  private pageKeyVisual$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public getPageKeyVisual(): Observable<string> {
    return this.pageKeyVisual$.asObservable();
  }

  public setPageKeyVisual(pageKeyVisual: string): void {
    this.pageKeyVisual$.next(pageKeyVisual);
  }



  // breadcrumb Class A

  private breadcrumbClassA$: BehaviorSubject<[string, string]> = new BehaviorSubject<[string, string]>(['', '']);

  public get_breadcrumbClassA(): Observable<[string, string]> {
    return this.breadcrumbClassA$.asObservable();
  }

  public set_breadcrumbClassA(breadcrumbClassA: [string, string]): void {
    this.breadcrumbClassA$.next(breadcrumbClassA);
  }




  // breadcrumb Class B

  private breadcrumbClassB$: BehaviorSubject<[string, string]> = new BehaviorSubject<[string, string]>(['', '']);

  public get_breadcrumbClassB(): Observable<[string, string]> {
    return this.breadcrumbClassB$.asObservable();
  }
  public set_breadcrumbClassB(breadcrumbClassB: [string, string]): void {
    this.breadcrumbClassB$.next(breadcrumbClassB);
  }




  // breadcrumb Class C

  private breadcrumbClassC$: BehaviorSubject<[string, string]> = new BehaviorSubject<[string, string]>(['', '']);

  public get_breadcrumbClassC(): Observable<[string, string]> {
    return this.breadcrumbClassC$.asObservable();
  }
  public set_breadcrumbClassC(breadcrumbClassC: [string, string]): void {
    this.breadcrumbClassC$.next(breadcrumbClassC);
  }


  //關於我們 - 內容滾動讀取的狀態
  private pageAboutContendLoadStatus = new BehaviorSubject<number>(1);

  getPageAboutContLoadStatus() {
    return this.pageAboutContendLoadStatus.asObservable();
  }

  updatePageAboutContLoadStatus(status: number) {
    this.pageAboutContendLoadStatus.next(status);
  }

  //關於我們 - 錨點
  private aboutAnchor$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  public setAboutAnchor(aboutAnchor: number): void {
    this.aboutAnchor$.next(aboutAnchor);
  }

  public getAboutAnchor(): Observable<number> {
    return this.aboutAnchor$.asObservable();
  }



  constructor() { }
}
