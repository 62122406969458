import { Component } from '@angular/core';

@Component({
  selector: 'page-loading-image',
  standalone: true,
  imports: [],
  templateUrl: './page-loading-image.component.html',
  styleUrl: './page-loading-image.component.less'
})
export class PageLoadingImageComponent {

}
