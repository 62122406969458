@if(!(isLoading$ | async)) {
    @if(loadingSwitch) {
        @defer {
            @if(imageSrc != undefined) {
            <div class="mask"></div>
            <img [src]="imageSrc" alt="{{alt}}" loading="lazy" />
            }
        } @placeholder(minimum 2s) {
            <page-loading-image width="20px" height="20px"></page-loading-image>
        }
    }@else {
        <img [src]="imageSrc" alt="{{alt}}" loading="lazy" />
    }
}

