import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavList } from '../asnt-nav/nav-list';
import { imgPath } from '../../app-path';
import { PageInfoService } from '../../service/page-info.service';



@Component({
  selector: 'asnt-page-based',
  template: ''
})
export class AsntPgaeBasedComponent {

  navList = NavList;
  imgPath = imgPath.mainVisual_desktop;
  getSubscription: Subscription = new Subscription();
  webSiteName: string = `雋揚國際股份有限公司 ASCENT DEVELOPMENT`;


  // 頁面型態
  pageType!: string;

  // 頁面順位
  pageOrder!: number;

  // 頁面主視覺檔名
  pageKeyVisual!: string;

  // 標題
  pageTitle_CH!: string;
  pageTitle_EN!: string;

  // 描述
  pageDescription!: string;

  // 麵包屑
  /* [名稱, 路徑 ]*/
  breadcrumbClassA!: [string, string];
  breadcrumbClassB!: [string, string];
  breadcrumbClassC!: [string, string];

  // 關鍵字
  pageKeyword!: string;

  defaultOffset:any;


  constructor(
    protected pageInfoService: PageInfoService,

    //protected dialog: MatDialog,
  ) {
   
  }

  ngOnInit() {
    this.pageInfoService.setPageType(this.pageType);
    this.pageInfoService.setPageOrder(this.pageOrder);
    this.pageInfoService.setPageTitle_CH(this.pageTitle_CH);
    this.pageInfoService.setPageTitle_EN(this.pageTitle_EN);
    this.pageInfoService.setPageDescription(this.pageDescription);
    this.pageInfoService.setPageKeyWords(this.pageKeyword);
    this.pageInfoService.setPageKeyVisual(this.pageKeyVisual);
    this.pageInfoService.set_breadcrumbClassA(this.breadcrumbClassA);
    this.pageInfoService.set_breadcrumbClassB(this.breadcrumbClassB);
    this.pageInfoService.set_breadcrumbClassC(this.breadcrumbClassC);
    this.pageInfoService.setAboutAnchor(1);

  }


  ngOnDestroy() {
    this.getSubscription.unsubscribe();
  }


  // openDialog(dialogComp: any): void {
  //   this.dialog.open(dialogComp, {
  //   });
  // }


}
